import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HelperValidator } from '../Models/ViewModels/helper/helperValidator';
// import { Role } from '../Models/DomainModel/User/Role';
// import { Employee } from '../Models/DomainModel/User/Employee';
// import { JwtHelperService } from '@auth0/angular-jwt';
// import { Menu } from '../Models/DomainModel/Menu';
// import { RolePermission } from '../Models/DomainModel/User/RolePermission';
// import { Helper } from '../Models/ViewModels/helper/helper';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  // public userRolePermission: RolePermission[] = [];
  // public userDetails: Employee;
  // public role: Role;

  // public menus: Menu[] = [];
  public url = '';
  // // public isAuthenticated: boolean = false;
  private loginUrl = '/login';
  public helper: HelperValidator = new HelperValidator();
  // public helper: Helper = new Helper();
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('accessToken');

    if (token != null) {
      console.log(this.jwtHelper.isTokenExpired(token));
      console.log(this.jwtHelper.decodeToken(token));
      console.log(this.jwtHelper.getTokenExpirationDate(token));
      console.log(new Date());
      if (this.jwtHelper.getTokenExpirationDate(token).getTime().valueOf() < Date.now()) {
        console.log('Token Expir');
      }
      return !this.jwtHelper.isTokenExpired(token);
    }
    return false;
    // Check whether the token is expired and return
    // true or false

  }
  public setRedirectUrl(url) {
    this.url = url;
  }
  public getLoginUrl(): string {
    return this.loginUrl;
  }
  public getItem(item: string): string {
    return localStorage.getItem(item) ? localStorage.getItem(item) : '';
  }
  constructor(public jwtHelper: JwtHelperService) {
    // this.helper.SelectValidator = '^(0+[1-9]|[1-9])[0-9]*$';
    // tslint:disable-next-line: max-line-length
    // this.helper.EmailValidator = '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$';
    // this.helper.MobileNumberValidator = '^[0-9]{10}$';
    // this.helper.UserNameValidator = '^[a-z A-Z0-9_-]{6,25}$';
    // this.helper.AddressValidator = '^[a-z A-Z0-9_-]{8,25}$';
    // this.helper.GstValidator = '^[a-zA-Z0-9]{15}$';
  }
}
