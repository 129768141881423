import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ToastModule } from 'primeng/toast';
// import { environment } from 'src/environments/environment.prod';
import { HelperService } from './Services/helper.service';
import { HttpRequestHandler } from './Helpers/HttpRequestHandler';
// import { JwtModule } from '@auth0/angular-jwt';
import { MessageService } from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { ThermalPrintModule } from 'ng-thermal-print';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { HelloComponent } from './thank-you/hello/hello.component';
import { environment } from 'src/environments/environment';

export function tokenGetter() {
  return localStorage.getItem('accessToken');
}

@NgModule({
  declarations: [
    AppComponent,
    ThankYouComponent,
    HelloComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ConfirmDialogModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    ToastModule,
    BrowserAnimationsModule,
    // ThermalPrintModule,
    ReactiveFormsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: [environment.webApiUrl],
        disallowedRoutes: ['localhost:3001/auth/']
      }
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestHandler, multi: true },
    MessageService, HelperService, Title
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
