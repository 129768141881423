<div class="container">
    <div class="container thankyou-container">
        <div class="row">

            <div class="col-md-12 thankyou-form">
                <div class="form-group">
                    <h3><img src="../../assets/images/icon_is.png" style="width: 32px;"> Thank you for using iS Repot
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <!-- <strong>Status: {{status}}</strong>
    <div>
        <input [(ngModel)]="ipAddress" type="text" placeholder="IP of printer with WebPRNT">
        <button (click)="connectToWebPrint()">Connect to WebPRNT</button>
    </div>

    <div>
        <button (click)="requestUsb()">Connect to USB</button>
    </div>

    <div>
        <button (click)="print()" [disabled]="status === false"> Print!</button>
    </div> -->
</div>

<app-hello [width]="'80mm'"></app-hello>

<button (click)="testPrint()" style="margin-top: 20px">
  Test Printer
</button>