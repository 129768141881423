import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'iS-POS-Report-Web';
  constructor(private messageService: MessageService) { }

  closeError() {
    this.messageService.clear('common_error');
    this.messageService.clear();
  }

  closeSuccess() {
    this.messageService.clear('common_suceess');
    this.messageService.clear();
  }
}
