<div [ngClass]="[classes.printAreaContainer, classes.fontMono]" [ngStyle]="{ width: width }">
  <div [ngClass]="classes.textCenter">Date: 12.01.2020</div>
  <div [ngClass]="classes.grid3Col">
    <div [ngClass]="[classes.singleLine,classes.textLeft]">Check-In: 09:00</div>
    <div [ngClass]="[classes.singleLine,classes.textRight]">Check-Out: 22:00</div>
  </div>
  <div [ngClass]="classes.singleLine">Check-In: 09:00</div>
  <div [ngClass]="classes.singleLine">Check-Out: 22:00</div>
  <div [ngClass]="classes.singleLine">&nbsp;</div>

  <div [ngClass]="classes.grid3Col">
    <div><strong>Item</strong></div>
    <div [ngClass]="classes.textRight"><strong>Backbar</strong></div>
    <div [ngClass]="classes.textRight"><strong>Price</strong></div>

    <div [ngClass]="[classes.gridBorder, classes.gridBorderDouble]"></div>

    <div [ngClass]="classes.maxLine2">Service 1</div>
    <div [ngClass]="classes.textRight">$0.5</div>
    <div [ngClass]="classes.textRight">$4.0</div>

    <div [ngClass]="classes.maxLine2">
      Service 1 Super Long Long Line That Is Long Service 1 Super Long Long Line
    </div>
    <div [ngClass]="classes.textRight">₹0.5</div>
    <div [ngClass]="classes.textRight">$4.0</div>

    <div [ngClass]="[classes.gridBorder, classes.gridBorderSolid]"></div>

    <div [ngClass]="[classes.colSpan2, classes.maxLine2]">
      <strong>Total</strong>
    </div>
    <div [ngClass]="classes.textRight"><strong>$8.0</strong></div>
  </div>
</div>