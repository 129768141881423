import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThankYouComponent } from './thank-you/thank-you.component';


const routes: Routes = [
  {
    path: 'pos',
    loadChildren: () => import('./pos/pos.module').then(m => m.PosModule)
  },
  {
    path: 'bucket',
    loadChildren: () => import('./bucket/bucket.module').then(m => m.BucketModule)
  },
  {
    path: 'thermal-print',
    loadChildren: () => import('./thermal-print/thermal-print.module').then(m => m.ThermalPrintModule)
  },
  { path: 'thank-you', component: ThankYouComponent },
  { path: '', redirectTo: '/thank-you', pathMatch: 'full' },
  { path: '**', redirectTo: '/thank-you' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
