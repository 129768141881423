import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThermalPrintService {
  printContent = ``;
  cssStyles = ``;

  constructor(@Inject(String)private  paperWidth: "80mm" | "58mm") { }

  addRawHtml(htmlEl) {
    this.printContent += `\n${htmlEl}`;
  }

  addLine(text) {
    this.addRawHtml(`<p>${text}</p>`);
  }

  addLineWithClassName(className, text) {
    this.addRawHtml(`<p class="${className}">${text}</p>`);
  }

  addEmptyLine() {
    this.addLine(`&nbsp;`);
  }

  addLineCenter(text) {
    this.addLineWithClassName("text-center", text);
  }

  setStyles(cssStyles) {
    this.cssStyles = cssStyles;
  }

  print() {
    const printerWindow = window.open(``, `_blank`);
    printerWindow.document.write(`
    <!DOCTYPE html>
    <html>
    
    <head>
      <title>Print</title>
      <style>
        html { padding: 0; margin: 0; width: ${this.paperWidth}; }
        body { margin: 0; }
        @media print {
          #printPageButton {
            display: none;
          }
        }
        ${this.cssStyles}
        
      </style>
      <script>
        window.onafterprint = event => {
          window.close();
        };
      </script>
    </head>

    <body>
      ${this.printContent}
    </body>
    
    </html>
    
    `);

    printerWindow.document.close();
    printerWindow.focus();
    printerWindow.print();
    // mywindow.close();
  }
}
