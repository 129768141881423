<ng-http-loader [backgroundColor]="'#b66dff'" [debounceDelay]="100" [extraDuration]="0" [minDuration]="0" spinner="sk-wave">
</ng-http-loader>
<!-- <div class="container"> -->

<router-outlet></router-outlet>
<!-- </div> -->

<!-- <p-confirmDialog #cd header="Confirmation" key="confirm_common" icon="pi pi-exclamation-triangle">
    <p-footer>
        <button type="button" icon="pi pi-times" class="btn btn-outline-danger btn-fw" (click)="cd.reject()">No</button>
        <button type="button" icon="pi pi-check" class="btn btn-gradient-primary btn-fw" (click)="cd.accept()">Yes</button>
    </p-footer>
</p-confirmDialog> -->

<p-toast position="center" key="error_common" (onClose)="closeError()" [autoZIndex]="true" sticky="sticky">
    <ng-template let-message pTemplate="message">
        <div style="text-align: center">
            <i class="mdi mdi-alert-outline" style="font-size: 3em;color: red;"></i>
            <h3>{{message.summary}}</h3>
            <p>{{message.detail}}</p>
        </div>
        <div class="ui-g ui-fluid">
            <div class="ui-g-12">
                <button type="button" icon="pi pi-check" class="btn btn-danger btn-fw" style="float: right;" (click)="closeError()">Ok</button>
            </div>
        </div>
    </ng-template>
</p-toast>

<p-toast position="center" key="common_suceess" (onClose)="closeError()" [autoZIndex]="true" sticky="sticky">
    <ng-template let-message pTemplate="message">
        <div style="text-align: center">
            <i class="mdi mdi-check" style="font-size: 3em;color: green;"></i>
            <h3>{{message.summary}}</h3>
            <p>{{message.detail}}</p>
        </div>
        <div class="ui-g ui-fluid">
            <div class="ui-g-12">
                <button type="button" icon="pi pi-check" class="btn btn-success btn-fw" style="float: right;" (click)="closeError()">Ok</button>
            </div>
        </div>
    </ng-template>
</p-toast>